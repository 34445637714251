<template>
  <define-product-count>
    <div aria-live="polite">
      <div
        v-if="productsLoading"
        class="h-4 shrink-0 skeleton self-center"
        style="width: 5.25rem"
      />
      <p
        v-else
        class="ws-nowrap text-sm c-grey-20 fw-light "
        data-test-id="list-product-count"
      >
        {{ total }} {{ $pluralize($t.products, total) }}
      </p>
    </div>
  </define-product-count>

  <div data-scroll-el="productList" />
  <div v-if="sideFilters" class="mb-5 flex items-end gap-2 container between <lg:hidden">
    <template v-if="hasFilters">
      <product-count />
      <select-sort
        v-model="sortState"
        :label="$t.sort"
        :options="sortOptions"
        class="ml-a"
        @update:model-value="sortBy"
      />
    </template>
    <!-- Skeleton -->
    <template v-else-if="productsLoading">
      <div class="w-full">
        <div class="mt-2 h-4 skeleton" style="width: 5.25rem" />
      </div>
      <div class="h-10 w-40 skeleton" />
    </template>
  </div>
  <base-sticky v-slot="{ isStuck }" name="headerPLP" :margin="header.sticky.margin">
    <div
      class="sticky transform bg-white duration"
      :class="[
        { 'lg:hidden': sideFilters },
        brandClasses.stickyHeader,
        isStuck ? ['z-50', brandClasses.stickyHeaderStuck] : 'z-2',
      ]"
      :style="`top: ${header.sticky.offset / 16}rem`"
    >
      <div class="f-col gap-4 p-4 container">
        <!-- Mobile filters -->
        <div v-if="hasFilters || products.length" class="flex items-center gap-y-2 wrap between lg:hidden">
          <vf-button
            :variant="filterAndSortCta.variant"
            :size="filterAndSortCta.size"
            :class="brandClasses.filterAndSortCta"
            @click="PanelFilters.open"
          >
            {{ $t.filterAndSort }}
            <vf-icon name="filters" :size="filterAndSortCta.iconSize" :class="brandClasses.filterAndSortCtaIcon" />
          </vf-button>
          <product-count />
        </div>
        <!-- Skeleton -->
        <div v-else-if="productsLoading" class="h-12 w-72 skeleton lg:hidden" />
        <!-- Desktop filters -->
        <div v-if="!sideFilters" class="<lg:hidden">
          <div v-if="hasFilters || products.length" class="flex items-start gap-3 bg-white between">
            <div class="flex gap-3 wrap">
              <vf-dropdown
                v-for="(filter, code) in filters"
                :key="code"
                :label="filter.label"
                :style-content="`max-height: 18rem; ${code === 'lowCurrent' ? 'width: 15rem;' : ''}`"
              >
                <filter-options
                  v-model="filters[code].selected"
                  :filter="filter"
                  layout="grid"
                  :loading="productsLoading"
                  :currency
                  @update:model-value="filterBy(code.toString())"
                />
              </vf-dropdown>
            </div>

            <div class="flex items-center gap-4">
              <select-sort
                v-if="products.length"
                v-model="sortState"
                :label="$t.sort"
                :options="sortOptions"
                @update:model-value="sortBy"
              />
              <product-count />
            </div>
          </div>
          <!-- Skeleton -->
          <div v-else-if="productsLoading" class="mb-4 flex between">
            <div class="flex gap-4">
              <div v-for="i in 8" :key="i" class="w-20 skeleton" style="height: 2.75rem" />
              <div class="w-40 skeleton" style="height: 2.75rem" />
            </div>
          </div>
        </div>

        <!-- Applied filters section -->
        <filter-display
          v-bind="{ filters, currency, sideFilters }"
          @remove="removeFilter"
          @clear="clearFilters"
        />
      </div>
    </div>
  </base-sticky>

  <panel-filters v-slot="attrs" :position="filtersPanelPosition" class-content="pb-0">
    <dialog-filters
      v-bind="attrs"
      :heading="title || $t.filterAndSort"
      :currency
      :total
      :loading="productsLoading || nextPageLoading"
      :filters
      :sort-state="sortState as string"
      :sorts="sorts[region]"
      @remove-filter="removeFilter"
      @clear-filters="clearFilters"
      @sort-change="onSortChange"
      @filter-item-change="onFilterItemChange"
    />
  </panel-filters>
</template>

<script lang="ts" setup>
import type { Catalog, Search } from '#root/api/clients/product/data-contracts'

const props = defineProps<{
  list: Catalog | Search | null
  productsLoading: boolean
  nextPageLoading: boolean
  /**
   * values provided by LaunchDarkly
   */
  sideFilters: boolean
  title?: string
}>()

const PanelFilters = createPanel('filters')
const [DefineProductCount, ProductCount] = createReusableTemplate()

const { brandClasses, filterAndSortCta, filtersPanelPosition } = useAppConfig().components.product.list
const { sorts } = useAppConfig().pages.plp
const { $t } = useNuxtApp()
const region = useRegion()
const header = useHeaderStore()

const {
  sortState,
  filters,
  sortOptions,
  sortBy,
  filterBy,
  removeFilter,
  clearFilters
} = useFilters(
  () => props.list?.filters || [],
  () => scrollToElement('productList', { offset: 0 })
)

const total = computed(() => props.list?.total || 0)
const currency = computed(() => props.list?.currency || 'USD')
const products = computed(() => props.list?.products || [])
const hasFilters = computed(() => !!Object.keys(filters.value)?.length)

const onSortChange = (sort: string) => {
  sortState.value = sort
  sortBy()
}

const onFilterItemChange = ({ code, selected }: { code: string, selected: string[] }) => {
  filters.value[code].selected = selected
  filterBy(code)
}
</script>
