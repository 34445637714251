<template>
  <div data-dynatrace="product-price" data-test-id="product-pricing" class="text-sm lh-1 ">
    <span class="gap-x-2 wrap i-flex fw-medium" :class="{ 'md:f-col md:gap-y-1': block }">
      <template v-if="isRange && !showDiscountedRanges">
        <span :class="[classCurrent, { 'c-red-30': isAllVariantsDiscounted }]">{{ current }}</span>
      </template>
      <template v-else>
        <span
          :class="
            current === original
              ? classCurrent
              : 'line-through c-grey-30 !c-grey-20'"
        >
          {{ isOmnibus ? historicBest : original }}
        </span>
        <span v-if="current !== original" class="c-brand-2 c-red-30 " :class="classCurrent">
          {{ current }}
          <template v-if="showDiscountPercentage && !isRange && price.percentagePriceDiscount">
            ({{ discountPercentage }})
          </template>
        </span>
      </template>
    </span>

    <div v-if="isOmnibus && showOriginalPriceForOmnibus" class="mt-2 w-fit flex gap-x-2 bg-grey-80 p-2 text-xs c-grey-10 ">
      {{ $t.originalPrice }}: {{ original }}
      <vf-toggletip placement="top-start">
        <template #trigger>
          <vf-icon name="info" />
        </template>
        {{ $t.omnibusTooltip }}
      </vf-toggletip>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { PriceFull } from '#types/product'
import type { CSSClass } from '#types/common'

const props = defineProps<{
  /**
   * Price to display
   * Example of strike through sales price range and current price range.
   *
   * $20-$40 - Original Price range: - strike through
   * $10-$30 - Current Range.
   * $20 - lowOriginal
   * $40 - highOriginal
   * $10 - lowCurrent
   * $30 - highCurrent
   */
  price: PriceFull
  /**
   * Currency of the price
   */
  currency: string
  classCurrent?: CSSClass
  /**
   * Sets displaying the discount amount as percentage
   */
  showDiscountPercentage?: boolean
  /**
   * Shows box with original price and omnibus tooltip below sale price.
   * Currently only shown on PDP and Quickshop
   */
  showOriginalPriceForOmnibus?: boolean
  /**
   * Show original price and discounted price below it
   * Currently only use on order Details
   */
  block?: boolean
}>()

const { $feature, $t } = useNuxtApp()
const { showDiscountedRanges } = useAppConfig().components.product.pricing

const isAllVariantsDiscounted = computed(() => props.price.highCurrent < props.price.lowOriginal)

const getPrice = (low: number, high: number) => {
  if (low === high)
    return low === 0 ? $t.free : useFormattedPrice(low, props.currency)
  return `${useFormattedPrice(low, props.currency)} - ${useFormattedPrice(high, props.currency)}`
}

const isOmnibus = computed(() => {
  const { lowCurrent, lowOriginal, historicBestPrice } = props.price
  return (
    $feature.showOmnibusPricing
    && historicBestPrice !== undefined
    && lowCurrent < lowOriginal
    && lowCurrent < historicBestPrice
  )
})

const original = computed(() => getPrice(props.price.lowOriginal, props.price.highOriginal))

const current = computed(() => getPrice(props.price.lowCurrent, props.price.highCurrent))

const historicBest = computed(() => useFormattedPrice(props.price.historicBestPrice ?? 0, props.currency))

const isRange = computed(() => props.price.lowCurrent !== props.price.highCurrent)

const discountPercentage = computed(() => {
  const { lowCurrent, percentagePriceDiscount, highOriginal } = props.price
  const percentage = isOmnibus.value
    ? percentagePriceDiscount
    : getPercentage(highOriginal, highOriginal - lowCurrent, 0)

  return `${$feature.showNegativeDiscountPercentageOnCatalog ? '-' : ''}${percentage}%`
})
</script>
