<template>
  <section data-test-id="product-reviews">
    <div class="text-sm space-y-6" :class="brandClasses.reviewsWrapper">
      <template v-if="reviewsCount">
        <h3 class="title-2" :class="brandClasses.reviewsHeading">
          {{ $t.reviewSnapshot }}
        </h3>

        <!-- Overview Section -->
        <section
          data-test-id="review-snapshot-section"
          class="grid gap-4"
          :class="[fitRatings.length ? 'lg:cols-3' : 'lg:cols-2']"
        >
          <div
            data-test-id="review-snapshot-average-reviews"
            class="f-col items-center b b-grey-50 px-4 py-6 ~md:flex-row"
            style="justify-content: space-around;"
          >
            <div
              v-if="ratio > recommendationRateThreshold"
              data-test-id="review-snapshot-customer-recommended"
              class="text-center <md:mb-4"
            >
              <p class="display-3">
                {{ ratio }}%
              </p>
              <p>
                {{ $t.customerRecommended }}
              </p>
            </div>
            <div class="text-center">
              <p v-if="rollup.averageRating" class="display-3">
                {{ rollup.averageRating }}
              </p>
              <vf-rating v-if="rollup.averageRating" class="mb-2" :value="rollup.averageRating" />
              <base-button
                v-if="reviewsCount"
                class="cursor-pointer underlined"
                @click="scrollToCustomerReviews"
              >
                {{
                  replaceAll(
                    $pluralize($t.numberOfReviews, reviewsCount),
                    { reviewsCount })
                }}
              </base-button>
            </div>
          </div>
          <div data-test-id="review-snapshot-rating-breakdown" class="b b-grey-50 p-4">
            <h3 class="mb-4 title-5">
              {{ $t.ratingBreakdown }}
            </h3>
            <div class="space-y-4">
              <template v-for="{ rating, reviewCount } in ratingHistogram" :key="rating">
                <vf-tooltip placement="top" class-trigger="grow" @click="handleFilterByRating(rating)">
                  <template #trigger>
                    <div
                      class="w-full flex items-center gap-2 rounded-md py-1"
                      :class="{ 'pl-1': ratingFilter === rating }"
                    >
                      <span class="shrink-0 text-xs" style="text-transform: lowercase;">
                        {{ rating }} {{ $t.stars.one }}
                      </span>
                      <vf-progress-bar :value="reviewCount" :max="reviewsCount" class="w-full rounded-xl" />
                      <span class="min-w-8 flex">{{ reviewCount }}</span>
                    </div>
                  </template>
                  {{ replaceAll($t.showOnlyStarReviews, { stars: rating }) }}
                </vf-tooltip>
              </template>
            </div>
          </div>
          <div v-if="fitRatings.length" data-test-id="review-snapshot-fit-rating" class="b b-grey-50 px-4 py-6">
            <h3 class="mb-4 title-5">
              {{ $t.fitRating }}
            </h3>
            <div class="space-y-4">
              <div v-for="(type, i) in fitRatings" :key="i">
                <p>
                  {{ type.name }}
                </p>
                <vf-meter
                  v-if="type.display_values?.length"
                  data-test-id="review-snapshot-fit-rating-scale"
                  :min-label="type.display_values[0]"
                  :max-label="type.display_values[type.display_values.length - 1]"
                  :max-value="type.display_values.length"
                  :value="getFitRatingValue(type)"
                />
              </div>
            </div>
          </div>
          <template v-if="reviewsConfig.showProsAndConsSnapshot && prosAndCons">
            <div v-for="({ title, values }, i) in prosAndCons" :key="i" class="b b-grey-50 px-4 py-6">
              <h3 class="mb-4 title-5">
                {{ title }}
              </h3>
              <ul class="space-y-2">
                <li v-for="({ label, count }, j) in values" :key="j" class="mb-2 flex">
                  <span class="grow">{{ label }}</span>
                  <span>{{ count }}
                  </span>
                </li>
              </ul>
            </div>
          </template>
        </section>
        <!-- Most liked Positive VS Negative Section -->
        <section
          v-if="$feature.showReviewFaceoffOnPDP && (mostLikedPositive || mostLikedNegative)"
          class="b b-grey-50 lg:grid lg:cols-2 lg:divide-x divide-grey-50 <lg:divide-y"
          data-test-id="product-reviews-pros-cons"
        >
          <!-- Positive Block -->
          <div v-if="mostLikedPositive" class="relative px-8 py-4 space-y-2">
            <h3 class="text-xs">
              {{ $t.mostLikedPositiveReview }}
            </h3>
            <div class="flex gap-1">
              <vf-rating :value="mostLikedPositive.rating" />
              <span>({{ getReviewCount(mostLikedPositive.rating) }})</span>
            </div>
            <h5 class="title-5 uppercase">
              {{ mostLikedPositive.headline }}
            </h5>
            <p v-if="mostLikedPositive.comments.length < reviewsConfig.mostLikedCommentTextThreshold">
              {{ mostLikedPositive.comments }}
            </p>
            <base-collapse
              v-else
              class-toggle="mt-2"
              class-content="min-h-14"
              class="break-words"
              disable-visibility-toggle
              inverted
            >
              <template #toggle="{ expanded }">
                <div class="flex items-center">
                  {{ $t.showCompleteReview }}
                  <vf-icon name="chevron" size="md" :dir="expanded ? 'up' : 'down'" />
                </div>
              </template>
              <template #default="{ expanded }">
                <p :class="{ 'line-clamp-3': !expanded }">
                  {{ mostLikedPositive.comments }}
                </p>
              </template>
            </base-collapse>
            <!-- VS Block -->
            <div
              v-if="mostLikedPositive && mostLikedNegative"
              class="vf-6ht6py "
            >
              {{ $t.versus }}
            </div>
          </div>
          <!-- Negative Block -->
          <div v-if="mostLikedNegative" class="px-8 py-4 space-y-2">
            <p class="text-xs">
              {{ $t.mostLikedNegativeReview }}
            </p>
            <div class="flex gap-1">
              <vf-rating :value="mostLikedNegative.rating" />
              <span>({{ getReviewCount(mostLikedNegative.rating) }})</span>
            </div>
            <h5 class="title-5 uppercase">
              {{ mostLikedNegative.headline }}
            </h5>
            <p v-if="mostLikedNegative.comments.length < reviewsConfig.mostLikedCommentTextThreshold">
              {{ mostLikedNegative.comments }}
            </p>
            <base-collapse
              v-else
              class-toggle="mt-2"
              class-content="min-h-14"
              class="break-words"
              disable-visibility-toggle
              inverted
            >
              <template #toggle="{ expanded }">
                <div class="flex items-center">
                  {{ $t.showCompleteReview }}
                  <vf-icon name="chevron" size="md" :dir="expanded ? 'up' : 'down'" />
                </div>
              </template>
              <template #default="{ expanded }">
                <p :class="{ 'line-clamp-3': !expanded }">
                  {{ mostLikedNegative.comments }}
                </p>
              </template>
            </base-collapse>
          </div>
        </section>
        <div class="text-center" data-test-id="product-reviews-add">
          <vf-button
            class="<md:w-full"
            :variant
            @click="openWriteReviewModal"
          >
            {{ $t.writeAReview }}
          </vf-button>
        </div>
        <!-- Reviews Rollup UGC Carousel -->
        <div
          v-if="$feature.showReviewsRollupCarouselOnPDP && (reviewUgcMedia && reviewUgcMedia.length >= 3)"
          data-test-id="product-reviews-media-carousel"
        >
          <vf-carousel
            v-style:w="{ sm: '15rem', md: '22rem', lg: '37rem' }"
            class-controls="-mx-10"
            :loop="reviewUgcMedia.length > 3"
            class="m-a flex justify-center"
          >
            <base-button
              v-for="(review, i) in reviewUgcMedia"
              :key="i"
              :aria-label="$t.reviewUgcViewInPopup"
              style="margin: 0 0.25rem"
              @click="ModalReviewUgc.keep().open({
                productId,
                reviewUgcMedia,
                activeIndex: i,
                isCarousel: true,
              })"
            >
              <base-picture
                v-if="review.media[0].type === 'image'"
                :alt="review.media[0].caption"
                :src="review.media[0].uri"
                height="112"
                width="112"
                :props-img="{ class: 'aspect-square w-a', style: 'height: 7rem' }"
              />
              <base-video
                v-else-if="review.media[0].type === 'video'"
                :src="review.media[0].uri"
                class="aspect-square"
                style="height: 7rem"
              />
            </base-button>
          </vf-carousel>
        </div>
      </template>

      <template v-if="showFilters">
        <!-- Mobile filters -->
        <div class="md:hidden">
          <base-button
            class="h-10 flex center b b-grey-10 rounded-sm px-6 <md:w-full space-x-2"
            @click="openFiltersPanel"
          >
            <span class="text-sm">{{ $t.filterReviews }}</span>
            <vf-icon name="filters" />
          </base-button>
        </div>
        <!-- Desktop Dropdown filters -->
        <div class="flex gap-3 wrap <md:hidden">
          <template v-for="(filter, code, index) in filters">
            <vf-dropdown
              v-if="filter.type === 'Checkbox'"
              :key="`${code}${index}`"
              :label="filter.label"
            >
              <filter-options
                :key="`reviewFilter-${code}`"
                v-model="filters[code].selected"
                :filter="filter"
                layout="grid"
                @update:model-value="filterBy()"
              />
            </vf-dropdown>
          </template>
        </div>
        <!-- Reviews Section -->
        <section data-test-id="product-reviews-search">
          <!-- Filters, Sorting -->
          <section class="space-y-4" data-test-id="total-reviews-by-customer">
            <div class="bg-grey-90 p-4 text-center lg:flex lg:items-center lg:between <md:-mx-4">
              <p v-if="reviewsCount" class="text-base <lg:mb-2">
                {{
                  replaceAll(
                    $pluralize($t.reviewedByCustomers, reviewsCount),
                    { count: reviewsCount })
                }}
              </p>
              <div v-if="$feature.showReviewSearchOnPDP">
                <form
                  role="search"
                  class="flex"
                  @submit.prevent="handleSearchSubmit"
                >
                  <vf-input
                    class="h-10 rounded-r-0 <lg:grow"
                    :class="brandClasses.reviewsSearchInput"
                  >
                    <template #input>
                      <base-input
                        v-model="searchTerm"
                        type="text"
                        :placeholder="`${$t.searchReviews}...`"
                        :aria-label="$t.searchReviews"
                        class="h-full"
                        maxlength="200"
                      />
                    </template>
                    <template #end>
                      <base-button
                        v-if="searchTerm"
                        :aria-label="$t.searchClearLabel"
                        @click="clearSearchTerm"
                      >
                        <vf-icon name="clear" :size="reviewsConfig.iconSize" />
                      </base-button>
                    </template>
                  </vf-input>
                  <base-button
                    type="submit"
                    :class="brandClasses.reviewsSearchSubmit"
                    :aria-label="$t.searchButtonLabel"
                  >
                    <vf-icon name="search" :size="reviewsConfig.iconSize" />
                  </base-button>
                </form>
              </div>
            </div>
            <div class="flex justify-end">
              <select-sort
                v-model="sort"
                class="<md:w-full"
                :label="$t.sort"
                :options="sortOptions"
                @update:model-value="sortReviews"
              />
            </div>
            <filter-display
              v-bind="{ filters, currency }"
              :heading="$t.filtersApplied"
              @remove="removeFilter"
              @clear="clearFilters"
            />
          </section>
        </section>
      </template>
      <vf-notification v-if="error" type="error" :dismissible="false">
        {{ errorMsg }}
      </vf-notification>
      <!-- Reviews List -->
      <section
        v-else-if="reviewsCount"
        id="customerReviews"
        :class="currentReviewsCount ? 'space-y-8' : 'pt-4'"
        data-scroll-el="customerReviews"
        data-test-id="customer-reviews"
      >
        <div class="space-y-6">
          <template v-if="reviewsPending">
            <product-reviews-skeleton v-for="i in pageSize" :key="i" />
          </template>
          <product-reviews-item
            v-for="review in reviews"
            v-else
            :key="review.reviewId"
            :product-id="productId"
            :review="review"
            :search-term="filters.search.selected && `${filters.search.selected[0]}`"
          />
        </div>
        <div class="f-col center md:flex-row md:between" :class="{ 'gap-1': pagesCount > 1 }">
          <div class="f-col center gap-2 md:flex-row md:gap-4">
            <p :class="currentReviewsCount ? 'c-grey-20' : 'text-base'" role="status" data-test-id="display-reviews">
              {{ currentReviewsDisplayed }}
            </p>
            <base-button
              v-if="currentReviewsCount"
              class="underlined"
              data-test-id="reviews-back-to-top"
              @click="scrollToCustomerReviews"
            >
              {{ $t.backToTop }}
            </base-button>
          </div>
          <div class="flex">
            <base-button v-if="currentPageNumber > 1" class="underlined" @click="showPrevious">
              {{ $t.previous }}
            </base-button>
            <span v-if="currentPageNumber > 1 && currentPageNumber < pagesCount" class="mx-2">|</span>
            <base-button
              v-if="pagesCount && currentPageNumber < pagesCount"
              class="underlined"
              data-test-id="reviews-next"
              @click="showNext"
            >
              {{ $t.next }}
            </base-button>
          </div>
        </div>
      </section>
      <div v-else class="text-center">
        <vf-button
          class="<md:w-full"
          :variant
          @click="openWriteReviewModal"
        >
          {{ $t.writeFirstReview }}
        </vf-button>
      </div>
    </div>

    <modal-review-write />
    <modal-review-ugc class-content="md:w-152 lg:w-164" size="md" />
    <modal-report-issue />
    <panel-filters position="left" />
  </section>
</template>

<script lang="ts" setup>
import { ApiErrorCode } from '#root/enums/api'
import type { MappedProsAndCons, ProductReviewsData, ProductReviewsParams } from '#types/components/product/reviews'
import type { FiltersMap, HistogramRatingFilter, SearchFilter } from '#types/filters'
import type {
  ProductReviewsRollup,
  ProductReviewsRollupFaceoff,
  ProductReviewsRollupProperty
} from '#root/api/clients/product/data-contracts'

const props = defineProps<{
  currency: string
  productId: string
  reviewsData?: ProductReviewsData | null
  reviewsPending: boolean
  error: any
}>()

const enum SpecialFilterCodes {
  Rating = 'rating',
  Search = 'search',
}

const reviewsQuery = defineModel<ProductReviewsParams>('modelValue', { required: true })

const ModalReviewWrite = createModal('review-write')
const PanelFilters = createPanel('filters')

const {
  brandClasses,
  reviews: { variant, pageSize, recommendationRateThreshold, ...reviewsConfig },
} = useAppConfig().pages.pdp

const { $t, $pluralize } = useNuxtApp()
const { ModalReviewUgc, ModalReportIssue } = useDialogsStore()

// Overview
const rollup = computed(() => props.reviewsData?.rollup || {} as ProductReviewsRollup)
const reviewsCount = computed(() => props.reviewsData?.rollup?.reviewCount || 0)
const ratingHistogram = computed(
  () => rollup.value?.ratingHistogram?.map((reviewCount, i) => ({ rating: i + 1, reviewCount })).reverse()
)
const fitRatings = computed(() =>
  rollup.value?.properties?.filter(({ display_type }) => display_type === 'histogram') || []
)
const ratio = computed(() => Math.round((rollup.value?.recommendedRatio || 0) * 100))

const errorId = computed(() => props.error?.cause?.errorId)
const errorMsg = computed(() =>
  errorId.value === ApiErrorCode.REVIEWS_MAX_LENGTH ? $t.reviewsTooManyFiltersError : $t.reviewsError)
const showFilters = computed(() => reviewsCount.value || errorId.value === ApiErrorCode.REVIEWS_MAX_LENGTH)

// Overview - Pros & cons
const prosAndCons = computed<MappedProsAndCons[] | undefined>(() => {
  if (!reviewsConfig.showProsAndConsSnapshot) return

  const prosCons: ProductReviewsRollupProperty[] = rollup.value?.properties?.filter(({ key }: ProductReviewsRollupProperty) => key === 'pros' || key === 'cons') || []
  if (reviewsConfig.prosAndConsMaxNumber && prosCons.length) {
    prosCons[0].values.splice(reviewsConfig.prosAndConsMaxNumber)
    prosCons[1].values.splice(reviewsConfig.prosAndConsMaxNumber)
  }
  return prosCons.map(({ key, values }) => ({
    title: $t[key],
    values
  })).reverse()
})

// UGC Carousel
const reviewUgcMedia = computed(() =>
  rollup.value?.media?.slice(0, 7).map((item) => ({
    reviewId: Number(item.id),
    details: {
      // @ts-expect-error incorrect contract: "nickname" is missing
      nickname: item.nickname,
      headline: '',
      locale: '',
      location: '',
      createdDate: 0,
      updatedDate: 0,
      comments: '',
      properties: []
    },
    metrics: {
      helpfulVotes: item.helpful_votes,
      notHelpfulVotes: item.not_helpful_votes,
      rating: 0,
      helpfulScore: 0
    },
    media: [item],
    ugcId: 0,
    legacyId: 0,
    badges: {
      isStaffReviewer: false,
      isVerifiedBuyer: false,
      isVerifiedReviewer: false
    }
  }))
)

// Most liked Positive VS Negative
const versusHelper = (data?: ProductReviewsRollupFaceoff) => {
  const fields = ['rating', 'comments', 'headline']
  if (fields.some((field) => data?.[field])) return data
  else return null
}

const mostLikedPositive = computed(() => versusHelper(rollup.value?.faceoff.positive))
const mostLikedNegative = computed(() => versusHelper(rollup.value?.faceoff.negative))

// Reviews list
const scrollToCustomerReviews = () => scrollToElement('customerReviews')
const reviews = computed(() => props.reviewsData?.reviews || [])
const currentReviewsCount = computed(() => props.reviewsData?.paging.totalResults || 0)
const pagesCount = computed(() => props.reviewsData?.paging.pagesTotal || 1)
const currentPageNumber = computed(() => props.reviewsData?.paging.currentPageNumber || 1)

const filtersToUse = rollup.value?.properties?.filter(({ key, display_type }) => !reviewsConfig.nonFilterKeys.includes(key) && display_type !== 'histogram') || []

const tagFilters = filtersToUse && Object.fromEntries(filtersToUse.map(({ key, name, values }) => {
  values.splice(reviewsConfig.filterOptionsMaxNumber)

  return [key, {
    code: key,
    facetId: key,
    label: name,
    options: values.map(({ count, label }) => ({
      label,
      id: label,
      items: count,
      checked: false,
      disabled: false
    })),
    type: 'Checkbox',
    selected: []
  }]
})) as FiltersMap

const ratingFilters = {
  code: SpecialFilterCodes.Rating,
  facetId: SpecialFilterCodes.Rating,
  label: 'Rating',
  options: ratingHistogram.value?.map(({ rating, reviewCount }) => ({
    label: `${rating} ${$pluralize($t.stars, rating)}`,
    id: `${rating} ${$pluralize($t.stars, rating)}`,
    items: reviewCount,
    checked: false,
    disabled: false
  })),
  type: 'HistogramRating',
  selected: []
} as HistogramRatingFilter

const searchFilter = {
  code: SpecialFilterCodes.Search,
  facetId: SpecialFilterCodes.Search,
  label: 'Search',
  type: 'Search',
  selected: []
} as SearchFilter

const filters: Ref<FiltersMap> = ref({ ...tagFilters, rating: ratingFilters, search: searchFilter })
const ratingFilter = ref(0)
const searchTerm = ref()

const filterBy = () => {
  const filtersQuery: string[] = []
  let searchQuery

  Object.values(filters.value)
    .filter(({ selected }) => selected?.length)
    .forEach(({ code, selected }) => {
      selected?.forEach((item) => {
        if (code === SpecialFilterCodes.Search)
          searchQuery = searchTerm.value
        else if (code === SpecialFilterCodes.Rating)
          filtersQuery.push(`${code}:${item.split(' ')[0]}`)
        else
          filtersQuery.push(`${code}:${item}`)
      })
    })
  reviewsQuery.value.filters = filtersQuery.length ? filtersQuery.join(',') : undefined
  reviewsQuery.value['paging.from'] = 0
  reviewsQuery.value.q = searchQuery
}

const handleSearchSubmit = () => {
  filters.value.search.selected = [searchTerm.value]
  filterBy()
}

const clearSearchTerm = () => {
  searchTerm.value = undefined
}

const handleFilterByRating = (rating: number) => {
  ratingFilter.value = rating !== ratingFilter.value ? rating : 0
  if (ratingFilter.value)
    filters.value.rating.selected = [`${rating} ${$pluralize($t.stars, rating)}`]
  else
    filters.value.rating.selected = []
  filterBy()
}

const removeFilter = ({ code, id }) => {
  filters.value[code].selected = (filters.value[code].selected as string[])?.filter((item) => item !== id)
  if (code === SpecialFilterCodes.Rating) ratingFilter.value = 0
  if (code === SpecialFilterCodes.Search) {
    clearSearchTerm()
    filters.value.search.selected = []
  }

  filterBy()
}

const clearFilters = () => {
  Object.keys(filters.value).forEach((code) => filters.value[code].selected = [])
  ratingFilter.value = 0
  filters.value.search.selected = []
  clearSearchTerm()
  filterBy()
}

const openFiltersPanel = () => {
  PanelFilters.keep().open({
    heading: 'Filter Reviews',
    currency: props.currency,
    total: currentReviewsCount.value,
    // Mobile filter panel does not contain rating filters
    filters: Object.fromEntries(Object.entries(filters.value).filter(
      (f) => f[0] !== SpecialFilterCodes.Rating && f[0] !== SpecialFilterCodes.Search
    )),
    onFilterItemChange: ({ code, selected }) => {
      filters.value[code].selected = selected
      filterBy()
    },
    onRemoveFilter: removeFilter,
    onClearFilters: clearFilters
  })
}

const openWriteReviewModal = () => {
  ModalReviewWrite.keep().open({
    pageId: props.reviewsData?.reviewsPageId || props.reviewsData?.pageId,
    productId: props.productId
  })
}

// sorting reviews
const sortOptions = [
  { value: 'Newest', label: $t.reviewSortOptionNewest },
  { value: 'MostHelpful', label: $t.reviewSortOptionMostHelpful },
  { value: 'HighestRating', label: $t.reviewSortOptionHighestRating },
  { value: 'LowestRating', label: $t.reviewSortOptionLowestRating },
  { value: 'Oldest', label: $t.reviewSortOptionOldest },
  { value: 'MediaSort', label: $t.reviewSortOptionMediaSort }
]

const sort = ref('Newest')

const sortReviews = (sortBy: string) => {
  reviewsQuery.value.sort = sortBy
}

const getReviewCount = (rating: number) =>
  ratingHistogram.value?.find(({ rating: r }) => r === rating)?.reviewCount || 0

// paginating reviews
const currentReviewsDisplayed = computed(() => {
  const start = ((currentPageNumber.value - 1) * pageSize) + 1
  const end = Math.min(currentReviewsCount.value, currentPageNumber.value * pageSize)
  return currentReviewsCount.value ? replaceAll($t.displayingReviews, { start, end }) : $t.noResults
})

const showPrevious = () => {
  reviewsQuery.value['paging.from'] = ((currentPageNumber.value - 1) * pageSize) - pageSize
  scrollToCustomerReviews()
}

const showNext = () => {
  reviewsQuery.value['paging.from'] = ((currentPageNumber.value + 1) * pageSize) - pageSize
  scrollToCustomerReviews()
}

onBeforeUnmount(() => {
  reviewsQuery.value['paging.from'] = 0
  reviewsQuery.value.sort = 'Newest'
  reviewsQuery.value.filters = undefined
  reviewsQuery.value.q = undefined
})
</script>
