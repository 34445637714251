<template>
  <div class="flex b-t b-grey-70 py-4 " data-test-id="rating-summary" :style="{ height: reviews.summaryHeight }">
    <div v-if="reviewsData?.rollup?.reviewCount" class="self-center">
      <p
        v-if="ratio > reviews.recommendationRateThreshold"
        class="mb-2 title-5 "
        data-test-id="rating-recommendation"
      >
        {{ replaceAll($t.customerRecommendedRatio, { ratio }) }}
      </p>
      <base-link to="#reviews" class="flex items-center gap-2">
        <vf-rating :value="reviewsData.rollup.averageRating" />
        <span class="text-sm lh-4 underlined !lh-3">
          {{ replaceAll(
            $pluralize($t.numberOfReviews, reviewsData?.rollup?.reviewCount),
            { reviewsCount: reviewsData?.rollup?.reviewCount })
          }}
        </span>
        <span class="sr-only">
          {{ replaceAll(
            $pluralize($t.viewNumberOfReviews, reviewsData?.rollup?.reviewCount),
            { reviewsCount: reviewsData?.rollup?.reviewCount })
          }}
        </span>
      </base-link>
    </div>
    <base-button
      v-else
      class="text-sm underlined"
      @click="ModalReviewWrite.open({
        pageId: reviewsData?.reviewsPageId || reviewsData?.pageId,
        productId: product.id,
      })"
    >
      {{ $t.writeFirstReview }}
    </base-button>

    <modal-review-write />
  </div>
</template>

<script lang="ts" setup>
import type { Product } from '#root/api/clients/product/data-contracts'
import type { ProductReviewsData } from '#types/components/product/reviews'

const props = defineProps<{
  product: Product
  reviewsData?: ProductReviewsData
}>()

const ModalReviewWrite = createModal('review-write')
const { reviews } = useAppConfig().pages?.pdp || {}

const ratio = computed(() => Math.round((props.reviewsData?.rollup?.recommendedRatio || 0) * 100))
</script>
